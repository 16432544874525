import React from 'react'
import Iframe from 'react-iframe'
import dotenv from 'dotenv'
import {
    GameContainer
} from './GameElements'


dotenv.config()
const GAME_URL = process.env.REACT_APP_GAME_URL

const GameSection = () => {
    return(
        <GameContainer>
              <Iframe url={GAME_URL}
                width="100%"
                height="100%"
                id="game-iframre"
                className="gameIframe"
                display="initial"
                position="relative"
                allowFullScreen/>
        </GameContainer>
    )
}

export default GameSection

import styled from 'styled-components'

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 25px;
  color: var(--secondary-text);
  width: 250px;
  height: 50px;
  cursor: pointer;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 8px 5px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ImageButton = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
  -moz-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.5);
  :active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

// Up Down Button
export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 34%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 25px;
  color: var(--primary-text);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 10px 5px -2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow:  0px 8px 5px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 0px -2px rgba(0, 0, 0, 0.7);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 997px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 450px;
  @media (min-width: 600px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

// Gif 
export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 5%;
  width: 320px;
  justify-content: stretched;
  align-items: flex-start;
  
  @media (min-width: 1200px) {
    width: 400px;
  }
  
  @media (min-width: 1400px) {
    width: 480px;
  }
  @media (min-width: 1600px) {
    width: 575px;
  }

  transition: width 0.5s;
`;
export const StyledLinkB = styled.a`
  color: var(--primary-text);
  text-decoration: none;
  font-size: 25px;
  textAlign: center;
  text-shadow: 0px 6px 5px rgba(0,0,0,0.8);
`;
export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

import React from 'react'
import dotenv from 'dotenv'
import { Card, PreviewImage } from './GalleryElements'

dotenv.config()


const SurvivorCard = (props) => {
    const ipfsBaseURL = props.contentBaseUrl
    const statsUrl = window.location.href + `/${props.index}`
    const imgUrl = `${ipfsBaseURL}/${process.env.REACT_APP_IMAGE_CID}/${props.index}.png`

    return(
        <Card >
            <a href={statsUrl}>
                <PreviewImage alt="subject-preview" src={imgUrl} />
            </a>
        </Card>
    )
}

export default SurvivorCard
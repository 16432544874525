import React from 'react'
import { NotFoundContainer, NotFoundTextWrapper, NotFoundText } from './NotFoundElements';

const NotFoundSection = () => {
    return(
        <NotFoundContainer>
            <NotFoundTextWrapper>
                <NotFoundText>
                    404 - Subject Not Found
                </NotFoundText>
            </NotFoundTextWrapper>   
        </NotFoundContainer>
    )
}

export default NotFoundSection;
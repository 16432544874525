import styled from 'styled-components'

export const ContentContainer = styled.div`
    max-width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 25px 10px 10px 0px;
    padding: 10px 0 0 10px;
`
    
export const Card = styled.div`
    position: relative;
    margin-bottom: 20px;
    max-width: 475px;
    max-height: 360px;
    width: 475px;
    margin: 10px 20px 10px 20px;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
    }
`

export const VideoWrapper = styled.div`
    border-radius: 0px 0px 0 0;
    
    padding-top: 50%;
    position: relative;
`

export const GalleryTitleContainer = styled.div`
    width: 100%;
    margin-top: 8px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    border-style: solid;
    border-color: white;
`

export const GalleryTitleWrapper = styled.div`
    border-style: solid;
    border-color: red;
`

export const GalleryTitle = styled.h2`
    font-size: 50pt;
    color: var(--secondary-text);
    text-shadow: 0px 5px 8px rgba(0,0,0,0.5);
`

export const GalleryContainer = styled.div`
    min-height: 100px;
    background-size: cover;
    background-color: var(--bg);;
   

`

export const GalleryEdge = styled.div`
    min-height: 25px;
    color: var(--secondary-text);
    width: 100%;
    background-color: var(--secondary);
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.8);
`

export const NoContentWrapper = styled.div`
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 10px;
`

export const NoContentText = styled.h3`
    text-align: center;
    font-size: 30pt;
    color: var(--secondary-text);
    text-shadow: 0px 5px 8px rgba(0,0,0,0.5);
`

export const PreviewImage = styled.img`
    width: 100%;
    object-fit: contain;
`
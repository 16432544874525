import React from 'react'
import * as s from "../../styles/globalStyles";
import { GalleryEdge, ContentContainer, GalleryTitleContainer, GalleryTitleWrapper, GalleryTitle, GalleryContainer, NoContentText, NoContentWrapper } from './GalleryElements';
import SurvivorCard from './SurvivorCard';


const GallerySection = (props) => {
    const walletIsEmpty = props.data.walletIDs.length === 0 ? true : false
    const contentDisplay = {display: walletIsEmpty ? 'none' : ''}
    const noContentDisplay = {display: walletIsEmpty ? '' : 'none'}
    const contentText = props.blockchain.account ? "Your wallet has no Zombie Stories" : "Connect your wallet to see your Zombie Stories"
    return(
        <GalleryContainer>
            <GalleryEdge>
            </GalleryEdge>
           
          
            <s.SpacerSmall />
            
            <GalleryTitleContainer className="gallery-title-container">
                <GalleryTitleWrapper className="gallery-title-wrapper">
                    <GalleryTitle className="gallery-wrapper">GALLERY</GalleryTitle>
                </GalleryTitleWrapper>
                
           </GalleryTitleContainer>
            <ContentContainer className='content-container' style={contentDisplay}>
                
                {props.data.walletIDs.map((id, index) => (
                    <SurvivorCard key={index} index={props.data.walletIDs[index]} contentBaseUrl={props.contentBaseUrl} />
                ))}
            </ContentContainer>
            <NoContentWrapper style={noContentDisplay} id='no-content-wrapper'>
                <NoContentText id='no-content-text'>{contentText}</NoContentText>
            </NoContentWrapper>
            <s.SpacerLarge />
            <s.SpacerLarge />
        </GalleryContainer>
    )
}

export default GallerySection

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import HeroSection from "../components/HeroSection/HeroSection";
import GallerySection from "../components/Gallery/GallerySection";
import dotenv from 'dotenv'

dotenv.config()

const Home = () => {
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
          NAME: "",
          SYMBOL: "",
          ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
        MOVIE_CID: process.env.REACT_APP_VIDEO_CID,
        IMAGE_CID: process.env.REACT_APP_IMAGE_CID,
        CONTENT_CARD_WIDTH: "475",
        CONTENT_CARD_HEIGHT: "360",
        IPFS_BASE_URL: "https://acidflasklabs.mypinata.cloud/ipfs",
      });
    
      const dispatch = useDispatch();
      const blockchain = useSelector((state) => state.blockchain);
      const data = useSelector((state) => state.data);
    
      const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
          dispatch(fetchData(blockchain.account));
        }
      };
    
      const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
    
        let config = await configResponse.json();
        SET_CONFIG(config);
      };
      
      useEffect(() => {
        getConfig();
       
      }, []);
    
      useEffect(() => {
        getData();
         
      }, [blockchain.account]);

    return(
        <s.Screen>
            <HeroSection CONFIG={CONFIG} data={data} blockchain={blockchain} />
            <GallerySection contentBaseUrl={CONFIG.IPFS_BASE_URL} data={data} blockchain={blockchain}/>
        </s.Screen>
    )
}

export default Home
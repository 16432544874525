import styled from 'styled-components'

export const NotFoundContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding: 50px 50px 50px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const NotFoundTextWrapper = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const NotFoundText = styled.h1`
    font-size: 100pt;

    @media screen and (max-width: 768px) {
        font-size: 60pt;
    };
`
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import NotFoundSection from '../components/NotFound/NotFoundSection'
import StoriesSection from '../components/Stories/StoriesSection'
import { getTotalSupply } from '../helpers/web3helpers';

const StoryShowcase = () => {
    const [totalSupply, setTotalSupply] = useState(0)
    const [subjectPageHidden, setSubjectPageHidden] = useState(true)
    let { id } = useParams()

    useEffect(() => {
        getTotalSupply().then(
            (supply) => {
                setTotalSupply(supply)
            }
        )
    }, [])

    useEffect(() => {
        if (parseInt(id) <= totalSupply) {
            setSubjectPageHidden(false)
        } else {
            setSubjectPageHidden(true)
        }

    }, [totalSupply])
    
    const validId = !Number.isInteger(parseInt(id)) || id < 1 || id > 666  ? false : true

    if (!validId) {
        return(
            <NotFoundSection />
        )
    }

    return(
        <> 
            <StoriesSection id={id} hidden={subjectPageHidden} />
        </>
    )
}

export default StoryShowcase
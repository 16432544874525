import React from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Home from './pages/home'
import StoryShowcase from "./pages/StoryShowcase"
import GameSection from "./components/Game/Game"

const App = () => {
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/project-infecticide" />
        </Route>
        <Route exact path="/project-infecticide" component={Home} />
        <Route path="/project-infecticide/game-demo">
          <div>
            <GameSection />
          </div>
        </Route>
        <Route path="/project-infecticide/:id">
          <StoryShowcase />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

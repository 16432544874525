import React, { useState, useEffect} from 'react'
import ReactPlayer from 'react-player'
import dotenv from 'dotenv'
import BlurredImg from './assets/zombiestories_subject_blur.png'
import {
    BlurredSubjectImg,
    StoriesContainer, 
    StoriesWrapper,
    StoriesTitleWrapper,
    SubjectTitleText,
    StoriesTitle,
    StoriesMovieContainer,
    StoriesMovieWrapper,
    StoriesStatsContainer,
    StoriesStatsWrapper,
    StatsTitleWrapper,
    StoriesStatsTitle,
    StoriesStatsList,
    StoriesStatsListItemWrapper,
    StatItemContent,
    StatHeader,
    StatItemTrait,
    StatItemValue,
    SpoilerContainer,
    SpoilerItemValue,
} from './StoriesElements'

dotenv.config()

const dataHidden = {
    "survivalRating": "Classified",
    "Survivor Name": "Classified",
    "Last Name": "",
    "Days Survived": "Classified",
    "Narrator Voice": "Classified",
    'Gun': "Classified",
    "Melee": "Classified",
    "Survivor Gender": "Classified",
    "Survivor Status": "Classified",
    "Job": "Classified",
    "Item": "Classified",
    "Desk Item": "Classified"
}

const StoriesSection = (props) => {
    const [data, setData] = useState(dataHidden)
    const [survivorName, setSurvivorName] = useState('')
    
    const movieUrl = `https://acidflasklabs.mypinata.cloud/ipfs/${process.env.REACT_APP_VIDEO_CID}/${props.id}.mp4`
    
    const getData = async () => {
        const metadataBaseUrl = `https://acidflasklabs.mypinata.cloud/ipfs/${process.env.REACT_APP_METADATA_CID}`
        const response = await fetch(`${metadataBaseUrl}/${props.id}.json`)
        const metadata = await response.json()
        
        var attributes = {}
        metadata.attributes.forEach(attributeObj => {
            attributes[attributeObj.trait_type] = attributeObj.value
        })
        attributes["survivalRating"] = metadata.survival_rating
        
        return attributes
    }

    useEffect(() => {
        if (!props.hidden) {
            getData(props.id).then(jsonData => setData(jsonData))
        } else {
            setData(dataHidden)
        }
    }, [props.hidden])

    useEffect(() => {
        let name = data["Survivor Name"] === undefined ? "" : `${data["Survivor Name"]} ${data["Last Name"]}`
        setSurvivorName(name)
    }, [data])

    return(
        <StoriesContainer id='stories-container'>
            <StoriesWrapper id='stories-wrapper'>
                <StoriesTitleWrapper id='stories-title-wrapper'>
                    <SubjectTitleText>Subject:&nbsp;&nbsp;</SubjectTitleText>
                    <StoriesTitle id='stories-title'>{survivorName}</StoriesTitle>
                </StoriesTitleWrapper>
                <StoriesMovieContainer id="stories-movie-container">
                    <StoriesMovieWrapper id='stories-movie-wrapper'>
                        {props.hidden ? 
                            <BlurredSubjectImg src={BlurredImg} /> 
                            :
                            <ReactPlayer url={movieUrl} controls={true} width='100%' style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} id='stories-movie' />
                        }
                    </StoriesMovieWrapper>
                </StoriesMovieContainer>
                <StoriesStatsContainer id='stats-container'>
                    <StoriesStatsWrapper id='stats-wrapper'>
                        <StatsTitleWrapper>
                            <StoriesStatsTitle id='stories-stats-title'>Subject Stats</StoriesStatsTitle>
                        </StatsTitleWrapper>
                        <StoriesStatsList id='stories-stats-list'>
                            <StoriesStatsListItemWrapper id='stat-header' style={{borderBottom: "0"}}>
                                <StatItemContent>
                                    <StatHeader>Trait</StatHeader>
                                    <StatHeader>Value</StatHeader>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-survival-rating'>
                                <StatItemContent>
                                    <StatItemTrait>Survival Rating:</StatItemTrait>
                                    <StatItemValue>{data["survivalRating"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-gender'>
                                <StatItemContent>
                                    <StatItemTrait>Survivor Gender:</StatItemTrait>
                                    <StatItemValue>{data["Survivor Gender"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-job'>
                                <StatItemContent>
                                    <StatItemTrait>Job:</StatItemTrait>
                                    <StatItemValue>{data["Job"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-gun'>
                                <StatItemContent>
                                    <StatItemTrait>Gun:</StatItemTrait>
                                    <StatItemValue>{data["Gun"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-melee'>
                                <StatItemContent>
                                    <StatItemTrait>Melee:</StatItemTrait>
                                    <StatItemValue>{data["Melee"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-item'>
                                <StatItemContent>
                                    <StatItemTrait>Item:</StatItemTrait>
                                    <StatItemValue>{data["Item"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-desk-item'>
                                <StatItemContent>
                                    <StatItemTrait>Desk Item:</StatItemTrait>
                                    <StatItemValue>{data["Desk Item"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-narrator-voice'>
                                <StatItemContent>
                                    <StatItemTrait>Narrator Voice:</StatItemTrait>
                                    <StatItemValue>{data["Narrator Voice"]}</StatItemValue>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-status'>
                                <StatItemContent>
                                    <StatItemTrait>Survivor Status:</StatItemTrait>
                                    <SpoilerContainer id="spoiler-container-days-survived">
                                        <StatItemValue>{data["Survivor Status"]}</StatItemValue>
                                    </SpoilerContainer>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                            <StoriesStatsListItemWrapper id='stat-Days-survived'>
                                <StatItemContent>
                                    <StatItemTrait>Days Survived:</StatItemTrait>
                                    <SpoilerContainer id="spoiler-container-days-survived">
                                        <SpoilerItemValue>{data["Days Survived"]}</SpoilerItemValue>
                                    </SpoilerContainer>
                                </StatItemContent>
                            </StoriesStatsListItemWrapper>
                        </StoriesStatsList>
                    </StoriesStatsWrapper>
                </StoriesStatsContainer>
            </StoriesWrapper>
        </StoriesContainer>
    )
}

export default StoriesSection
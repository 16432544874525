import styled from 'styled-components'

export const StoriesContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 100px 10px 100px 10px;
    
    background-size: cover;
    background-color: var(--bg);;
   
    @media screen and (max-width: 768px) {
        padding: 100px 5px 100px 5px;
    };
`

export const StoriesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px;
    max-width: 100%;
    width: 100%;
    height: auto;
`

export const StoriesTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    width: 100%;
`

export const SubjectTitleText = styled.h2`
    font-size: 40pt;
    text-align: center;
    color: rgba(225, 184, 114, 1.0);
`

export const StoriesTitle = styled.h2`
    font-size: 40pt;
    color: white;
    text-align: center;
    @media screen and (max-width: 300px) {
        font-size: 30pt;
    };
`

export const StoriesMovieContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
    
export const StoriesMovieWrapper = styled.div`
    border-radius: 0px 0px 0 0;
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.5);
    
    width: 475px;
    height: 360px;
    position: relative;
`

export const StoriesStatsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const StoriesStatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    max-width: 600px;
    width: 100%;
    height: auto;

    padding: 0 20px 0 20px;
`

export const StatsTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

export const StoriesStatsTitle = styled.h3`
    font-size: 35pt;
    align-text: center;
    color: rgba(225, 184, 114, 1.0);

    @media screen and (max-width: 768px) {
        font-size: 20pt;
    };
`

export const StoriesStatsList = styled.ul`
    margin: 5px 0 0 5px;
    width: 100%;
`

export const StoriesStatsListItemWrapper = styled.li`
    margin: 20px 0 10px 0;
`

export const StatItemContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
`

export const StatHeader = styled.p`
    font-size: 30pt;
    color: rgba(225, 184, 114, 1.0);

    @media screen and (max-width: 768px) {
        font-size: 20pt;
    };
`

export const StatItemTrait = styled.p`
    font-size: 20pt;
    color: white;
    padding-left: 10px;

    @media screen and (max-width: 768px) {
        font-size: 15pt;
    };
`
    
export const StatItemValue = styled.p`
    font-size: 20pt;
    color: white;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
        font-size: 15pt;
    };
`

export const SpoilerContainer = styled.div`
    // position: relative;
    height: 100%;
    width: auto;
    min-width: 200px;
    background-color: white;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: no-wrap;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(215, 215, 215, 0.0);
    }

`

export const SpoilerItemValue = styled.p`
    font-size: 20pt;
    color: white;
    padding-right: 10px;
    // position: absolute;
    // align-self: flex-end;
    // display: none;
    z-index: 1;

    @media screen and (max-width: 768px) {
        font-size: 15pt;
    };
`

export const BlurredSubjectImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`
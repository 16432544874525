import Web3 from 'web3';

export const getTotalSupply = async () => {
        
    const configResponse = await fetch("/config/config.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
      });    
    const config = await configResponse.json();
    
    const abiResponse = await fetch("/config/abi.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
    const myAbi = await abiResponse.json();

    const web3 = new Web3("https://rpc.moonriver.moonbeam.network")
    const { ethereum } = window
    web3.setProvider(ethereum)

    const contract = new web3.eth.Contract(myAbi, config.CONTRACT_ADDRESS)
    
    const totalSupply = await contract.methods.totalSupply().call()
    
    return totalSupply
}


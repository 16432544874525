import React, { useState } from 'react'
import * as s from "../../styles/globalStyles";
import { useDispatch } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { StyledButton, ImageButton, StyledRoundButton, ResponsiveWrapper, StyledLogo, StyledImg, StyledLink , StyledLinkB} from './HeroSectionElements'

const truncate = (input, len) => {
  return input.length > len ? `${input.substring(0, len)}...` : input;
}
  
const HeroSection = (props) => {
    const [mintAmount, setMintAmount] = useState(1);
    const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
    const [claimingNft, setClaimingNft] = useState(false);

    const dispatch = useDispatch();
      
    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
        newMintAmount = 1;
        }

        let maxAmount = props.CONFIG.MAX_SUPPLY - props.data.totalSupply;
        if( newMintAmount >  maxAmount) {newMintAmount = maxAmount;}
        
        setMintAmount(newMintAmount);
    };
      
    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 6) {
        newMintAmount = 6;
        }

        let maxAmount = props.CONFIG.MAX_SUPPLY - props.data.totalSupply;
        if( newMintAmount >  maxAmount) { newMintAmount = maxAmount;}

        setMintAmount(newMintAmount);
    };

    const getData = () => {
        if (props.blockchain.account !== "" && props.blockchain.smartContract !== null) {
          dispatch(fetchData(props.blockchain.account));
        }
        console.log(props.data.paused);
    };

    const claimNFTs = () => {
        let cost = props.CONFIG.WEI_COST;
        let gasLimit = props.CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${props.CONFIG.NFT_NAME}...`);
    
        setClaimingNft(true);
        props.blockchain.smartContract.methods
          .mint(mintAmount)
          .send({
            gasLimit: String(totalGasLimit),
            to: props.CONFIG.CONTRACT_ADDRESS,
            from: props.blockchain.account,
            value: totalCostWei,
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `${props.CONFIG.NFT_NAME} is yours!`
            );
            setClaimingNft(false);
            dispatch(fetchData(props.blockchain.account));
            })
    }

    const getMintButtonText = (claimingNft, paused) => {
      if (claimingNft) {
        return "BUSY"
      } else if (paused) {
        return "PAUSED"
      } else {
        return "MINT"
      }
    }

    
       
    const getFeedback = ( feedback, paused) => {
      if (paused) {
        return "Minting starts 10/31 at 2pm PST"
      } else {
        return feedback
      }
    }

    return(
    <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--secondary)", minHeight: "100vh" }}
        image={props.CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpg" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.Container jc={"center"} ai={"center"} style={{ width: "60%" }}>
       
        <s.SpacerSmall />
        <s.TextDescription
                   style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    fontSize: 32, textShadow: "0px 3px 6px rgba(0,0,0,1.9)",
                    lineHeight: 1.2
                  }}
                >Zombie Defense Game Coming Soon!
        </s.TextDescription>
        <s.SpacerSmall />
        <s.TextDescription
                   style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                    fontSize: 28, textShadow: "3px 3px 6px rgba(0,0,0,1.9)",
                    lineHeight: 1.2
                  }}
                >
                Equip your survivors to gain their stats and equipment. Earn NFTs and more by playing. Join our Discord for more details.

        </s.TextDescription>
        </s.Container>
       
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example_left.gif"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
          > <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                color: "var(--accent-text)",
                letterSpacing: 5,
                textShadow: "0px 7px 5px rgba(0,0,0,0.8)",
              }}
            >
              {props.data.totalSupply} / {props.CONFIG.MAX_SUPPLY}
            </s.TextTitle>
           
            <s.SpacerSmall />
            {Number(props.data.totalSupply) >= props.CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" ,textShadow: "0px 7px 5px rgba(0,0,0,0.8)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                
                <StyledLinkB target={"_blank"} href={props.CONFIG.MARKETPLACE_LINK} >
                  {props.CONFIG.MARKETPLACE}
                  
                </StyledLinkB>
              </>
            ) : (
              <> 
                <s.TextTitle
                  style={{ textAlign: "center", 
                  color: "var(--accent-text)",
                  fontSize: 35, 
                  textShadow: "0px 7px 5px rgba(0,0,0,0.8)" }}
                >
                  {props.CONFIG.DISPLAY_COST}{" "}
                  {props.CONFIG.NETWORK.SYMBOL} per Mint
                </s.TextTitle>
                {props.blockchain.account === "" ||
                props.blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: 30, textShadow: "0px 7px 5px rgba(0,0,0,0.8)",
                        lineHeight: 1.2
                      }}
                    >
                      Connect to the {props.CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {props.blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                            fontSize: 35, textShadow: "0px 7px 5px rgba(0,0,0,0.8)",
                          }}
                        >
                          {props.blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                          fontSize: 45, textShadow: "0px 7px 5px rgba(0,0,0,0.8)"
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      
                      <StyledButton
                        disabled={claimingNft || props.data.paused  ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {getMintButtonText(claimingNft, props.data.paused)}
                      </StyledButton>
                    </s.Container>

                    <s.SpacerMedium />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: 27,
                        textShadow: "0px 7px 5px rgba(0,0,0,0.6)"
                      }}
                    >
                      { getFeedback(feedback, props.data.paused ) }
                    </s.TextDescription>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: 25,
                      }} >

                      <StyledLink target={"_blank"} href={props.CONFIG.SCAN_LINK}>
                        {truncate(props.CONFIG.CONTRACT_ADDRESS, 15)}
                      </StyledLink>
                    </s.TextDescription>

                  </>
                )}
              </>
            )}
       
           
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example_right"}
              src={"/config/images/example_right.gif"}
            />
          </s.Container>
         
         </ResponsiveWrapper>
         <s.SpacerMedium />
         <StyledLinkB 
              target={"_blank"} href={props.CONFIG.MARKETPLACE_LINK}>
                          {truncate(props.CONFIG.MARKETPLACE, 30)}
          </StyledLinkB>
         <s.SpacerSmall />
            <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
              <s.SpacerSmall />
              <s.Container flex={1} jc={"center"} ai={"center"} fd={"row"} >
                <a href="https://twitter.com/AcidFlaskLabs" target="_blank" rel="noopener noreferrer">
                  <ImageButton src={"/config/images/icon_twitter.png"}  />
                </a>
                <s.SpacerMedium />
                <a href="https://discord.gg/tkvEDsBj9w" target="_blank" rel="noopener noreferrer">
                  <ImageButton src={"/config/images/icon_discord.png"} />
                </a>
              </s.Container>
            </s.Container>
    </s.Container>

)}

export default HeroSection
